
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cdd-backoffice-primary: mat-palette($mat-indigo);
$cdd-backoffice-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cdd-backoffice-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$cdd-backoffice-theme: mat-light-theme($cdd-backoffice-primary, $cdd-backoffice-accent, $cdd-backoffice-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cdd-backoffice-theme);

@import 'angular2-multiselect-dropdown/themes/default.theme.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./vendor/styles/_cdd/cdd";
@import "./vendor/styles/_custom-variables/features";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media(min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

// Ngx-Charts ********************************************************************

g {
  font-size: 11pt !important;
  font-weight: 500 !important;
}

.chart-legend .legend-labels {
  width: 230px !important;
  max-height: none !important;
}

.chart-legend .legend-label {
  color: #8d8d8d !important;
}
// *******************************************************************************

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

html {
  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation .25s;
  }
  .dropdown-menu {
    animation: ngFloatingElementAnimation .1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .5; }
}
@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@if $enable-rtl-support {
  @include keyframes(ngRtlSlideModalAnimation) {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }

  @include feature-rtl {
    &.modal-slide .modal-dialog {
      animation: ngRtlSlideModalAnimation .15s ease-out;
    }
  }
}

@if $enable-material-style {
  @include keyframes(ngMaterialModalBackdropAnimation) {
    0% { opacity: 0; }
    100% { opacity: .2; }
  }

  .material-style ngb-modal-backdrop.modal-backdrop {
    opacity: .2;
    animation: ngMaterialModalBackdropAnimation .15s ease-out;
  }
}

@include feature-rtl(false) {
  // Prevent horizontal scrollbar
  .dz-hidden-input {
    right: 0 !important;
    left: auto !important;
  }
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}


.p-inputtext{
    font-size: 0.8rem !important;
}

.p-calendar{
    width: 100%;
}

.table th{
    z-index: 1 !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.border-comentario {
    border: 1px solid #d3dfe2;
    border-radius: 5px;
    padding: 5px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #228be6 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background: #228be6 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #228be6 !important;
}

.search-input {
    border: 1px solid rgba(24, 28, 33, 0.1);
    padding: 10px;
    border-radius: 0em 1em 1em 0em;
    margin-left: -2px;
    cursor: pointer;
    align-self: center;
    color: #228be6;
}

.w-15 {
    width: 15%;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #228be6 !important;
    border: 1px solid #228be6;
}

.mat-badge-content {
    background: #228be6;
}

.text-lightblue {
    color: rgb(34, 139, 230);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #2c5b86 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #2c5b8682;
}
@import '../_appwork/include';
@import '../../libs/ngx-toastr/ngx-toastr.scss';
//@import '../pages/acao.scss';

.cor-padrao{
    color: $cor-padrao;
}

.cor-danger{
    color: $red;
}

.cor-warning{
    color: $yellow;
}

.cor-black{
    color: $black;
}

.cor-white{
    color: white;
}

.cor-success{
    color: $green;
}

.background-color-white{
    background-color: white !important;
}

.font-size-20pt{
    font-size: 20pt;
}

.font-size-15pt{
    font-size: 15pt;
}

.font-size-12pt{
    font-size: 12pt;
}

.font-size-11pt{
    font-size: 11pt;
}

.display-flex{
    display: flex;
}

.visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
  }

.text-align-center{
    text-align: center;
}

.text-align-right{
    text-align: right;
}

.text-align-left{
    text-align: left;
}

.table-modal-tabs-scroll{
    height: 170px;
}

.scroll-modal-cobradores{
    input {
        height: 15px !important;
    }
}

.primeira-linha-highlight{
    tr:first-child{
        background-color: #dedede;
    }
}

.border-padrao{
    border: 1px solid rgba(24, 28, 33, 0.1);
    border-radius: 0.25rem;
    padding: 3px;
}

.no-border-top{
    border-top: none ;
}

.no-boder-bottom{
    border-bottom: none ;
}

.cursor-pointer-padrao:hover{
    cursor: pointer !important;
}

.scroll-bar-padrao::-webkit-scrollbar { height: 10px; width:10px; background:#e3e0d6; }
.scroll-bar-padrao::-webkit-scrollbar-track { background: rgba(0,0,0,0.1); }
.scroll-bar-padrao::-webkit-scrollbar-thumb { border-radius:10px; background:#8b8778;  }


.multi-select-myclass{
    .c-btn {
        background: #fff;
        border: 1px solid #ccc;
        color: #333; }
      
      .selected-list .c-list .c-token {
        background: #0079FE; }
        .selected-list .c-list .c-token .c-label {
          color: #fff; }
        .selected-list .c-list .c-token .c-remove svg {
          fill: #fff; }
      
      .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
        fill: #333; }
      
      .dropdown-list ul li:hover {
        background: #f5f5f5; }
      
      .arrow-up, .arrow-down {
        border-bottom: 15px solid #fff; }
      
      .arrow-2 {
        border-bottom: 15px solid #ccc; }
      
      .list-area {
        border: 1px solid #ccc;
        background: #fff;
        box-shadow: 0px 1px 5px #959595; }
      
      .select-all {
        border-bottom: 1px solid #ccc; }
      
      .list-filter {
        border-bottom: 1px solid #ccc; }
        .list-filter .c-search svg {
          fill: #888; }
        .list-filter .c-clear svg {
          fill: #888; }
      
      .pure-checkbox input[type="checkbox"]:focus + label:before, .pure-checkbox input[type="checkbox"]:hover + label:before {
        border-color: #0079FE;
        background-color: #f2f2f2; }
      
      .pure-checkbox input[type="checkbox"] + label {
        color: #000; }
      
      .pure-checkbox input[type="checkbox"] + label:before {
        color: #0079FE;
        border: 1px solid #0079FE; }
      
      .pure-checkbox input[type="checkbox"] + label:after {
        background-color: #0079FE; }
      
      .pure-checkbox input[type="checkbox"]:disabled + label:before {
        border-color: #cccccc; }
      
      .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
        background-color: #cccccc; }
      
      .pure-checkbox input[type="checkbox"] + label:after {
        border-color: #ffffff; }
      
      .pure-checkbox input[type="radio"]:checked + label:before {
        background-color: white; }
      
      .pure-checkbox input[type="checkbox"]:checked + label:before {
        background: #0079FE; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"]:focus + label:before, .single-select-mode .pure-checkbox input[type="checkbox"]:hover + label:before {
        border-color: #0079FE;
        background-color: #f2f2f2; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"] + label {
        color: #000; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"] + label:before {
        color: transparent !important;
        border: 0px solid #0079FE; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
        background-color: transparent !important; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"]:disabled + label:before {
        border-color: #cccccc; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
        background-color: #cccccc; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
        border-color: #0079FE; }
      
      .single-select-mode .pure-checkbox input[type="radio"]:checked + label:before {
        background-color: white; }
      
      .single-select-mode .pure-checkbox input[type="checkbox"]:checked + label:before {
        background: none !important; }
      
      .selected-item {
        background: #e9f4ff; }
      
      .btn-iceblue {
        background: #0079FE;
        border: 1px solid #ccc;
        color: #fff; }
      
   
}

.linha-selecionada{
    background-color: #eaeaea;
}


/* *****************************************************************************
 * Navbar
 */

 .demo-navbar-messages .dropdown-toggle,
 .demo-navbar-notifications .dropdown-toggle,
 .demo-navbar-user .dropdown-toggle,
 .demo-navbar-messages.b-nav-dropdown .nav-link,
 .demo-navbar-notifications.b-nav-dropdown .nav-link,
 .demo-navbar-user.b-nav-dropdown .nav-link {
   white-space: nowrap;
 }
 
 .demo-navbar-messages .dropdown-menu,
 .demo-navbar-notifications .dropdown-menu {
   overflow: hidden;
   padding: 0;
 }
 
 @media (min-width: 992px) {
   .demo-navbar-messages .dropdown-menu,
   .demo-navbar-notifications .dropdown-menu {
     margin-top: .5rem;
     width: 22rem;
   }
 
   .demo-navbar-user .dropdown-menu {
     margin-top: .25rem;
   }
 }

 .swal2-container {
    z-index: 9999 !important;
 } 

 .disabledDiv {
    pointer-events: none;
    opacity: 0.7;
}

.disabledDiv ul, .disabledDiv li {
    pointer-events: initial;
}